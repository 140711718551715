<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="org">
      <v-card-title class="headline">Permissions for {{org.name}}</v-card-title>
      <v-card-text>      
        <p>
          By default only you have access to your organization. You can optionally grant access to others as well. To do this you need to get their profile ID. They can easily find that by going to the 'Edit Profile' page and then scroll to the bottom of the page to copy their profile ID. 
        </p>
        <p>
          Please note that the persons you permit access will have the exact same permissions as you have and can therefor also invite others.
        </p>

        <v-alert v-if="!orgUtil.isProPlan()" type="info" outlined>
          <p><router-link class="info--text" :to="{name: 'orgmanagerSubscription', params: {id:org.id}}">Upgrade to a PRO plan</router-link> to be able to set permissions.</p>
        </v-alert>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="permissions"
        sort-by="name"
        :items-per-page="25"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Permissions</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="accent" class="mb-2" :disabled="!orgUtil.isProPlan()" @click="addPermissions">Add</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-4" @click="deletePermissions(item.name, item.id)">
            fa-trash
          </v-icon>
        </template>
        <template v-slot:no-data>
          <i>No extra permissions granted, only you will have access to this organization.</i>
        </template>
      </v-data-table>
    </v-card>
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Org",
  components: {
  },
  props: {
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
      permissions: [],
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getGroup(id) {
      this.permissions = (await orgManagerService.getPermissions(id)).data.data || [];
    },

    async addPermissions() {
      var profileId = prompt('Please enter the profile ID of the user you want to give permissions:');
      if (profileId) {
        const response = (await orgManagerService.addPermissions(this.event.id, profileId)).data;
        await this.getData();
        this.$helpers.toastResponse(this, response, 'Permission added successfully.');
      }
    },

    async deletePermissions(name, profileId) {
      if (confirm(`Are you sure you want to remove access by ${name}?`)) {
        const response = (await orgManagerService.deletePermissions(this.event.id, profileId)).data;
        await this.getData();
        this.$helpers.toastResponse(this, response, 'Permission revoked successfully.');
      }
    },

    async getData() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },

  },
  computed: {
    
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Permissions', disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    ...mapGetters({
      user: "user"
    })
  },
  
};
</script>

